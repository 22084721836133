<template>
  <container>
    <page-header centered>
      <h1>Import Your Team</h1>
    </page-header>
    <template v-if="loading">
      <grid centered stackable doubling class="loading-screen">
        <grid-column centered sixteen>
          <loading :loading="loading" inline size="large" />
          <h3>⏳ Importing People</h3>
          <btn variant="default" @click.native="openFinchHelpPage">
            Learn About Our Integrations
          </btn>
        </grid-column>
      </grid>
    </template>
    <grid v-else centered stackable doubling>
      <grid-column centered sixteen>
        <g-image
          v-if="finchIntegration"
          centered
          class="integration-img"
          :src="finchIntegration.logo"
          alt="HR integration logo"
        />
      </grid-column>
      <grid-column v-if="block === 'start'" centered eight>
        <i class="circular inverted checkmark icon green big" />
        <h3>
          We connected to your
          {{ finchIntegration && finchIntegration.displayName }} account!
        </h3>
        <btn
          class="btn"
          variant="primary"
          :loading="syncLoading"
          @click.native="syncFinch"
        >
          Continue
        </btn>
      </grid-column>
      <grid-column v-else-if="block === 'manual'" centered eight>
        <h1>📧</h1>
        <h3>
          Integration process with
          {{ finchIntegration && finchIntegration.displayName }} started!
        </h3>
        <p>
          Thanks for starting the integration process. Please follow the
          previous instructions to give Gather access to your
          {{ finchIntegration && finchIntegration.displayName }} account. Once
          we have access, it will take about 2 weeks to import your information.
          We'll be in touch when your integration is ready.
        </p>
        <flex justify="center">
          <btn class="btn" variant="default" @click.native="openFinchHelpPage">
            Learn About Our Integrations
          </btn>
          <btn
            class="btn"
            variant="primary"
            @click.native="$router.push({ name: 'settings' })"
          >
            Go to Settings
          </btn>
        </flex>
      </grid-column>
      <grid-column v-else-if="block === 'synced'" centered six>
        <i class="circular inverted checkmark icon green big" />
        <h3>{{ totalUsers.length }} People Imported</h3>
        <g-table basic fixed class="import-table" single-line unstackable>
          <table-body>
            <table-row>
              <table-cell> Onboarding </table-cell>
              <table-cell right-aligned>
                {{ onboardingUsers.length }}
              </table-cell>
            </table-row>
            <table-row>
              <table-cell> Active </table-cell>
              <table-cell right-aligned>
                {{ activeUsers.length }}
              </table-cell>
            </table-row>
            <table-row>
              <table-cell> Alumni </table-cell>
              <table-cell right-aligned>
                {{ inactiveUsers.length }}
              </table-cell>
            </table-row>
          </table-body>
        </g-table>

        <flex justify="center">
          <btn class="btn" variant="default" @click.native="openFinchHelpPage">
            Learn About Our Integrations
          </btn>

          <btn
            class="btn"
            variant="primary"
            @click.native="$router.push({ name: 'people' })"
          >
            Complete
          </btn>
        </flex>
      </grid-column>
      <grid-column v-else-if="block === 'error'" centered eight>
        <h1>😔</h1>

        <template v-if="finchIntegration">
          <h3>Connection Partially Successful</h3>
          <p>
            We successfully connected to {{ finchIntegration.displayName }}
            but had an issue importing your people to Gather.
          </p>
        </template>
        <template v-else>
          <h3>Import Failed</h3>
          <p>We failed to connect to your HR Platform.</p>
        </template>

        <flex justify="center">
          <btn class="btn" variant="default" @click.native="openZendeskWidget">
            Contact Gather Team
          </btn>

          <btn
            class="btn"
            variant="primary"
            @click.native="$router.push({ name: 'settings' })"
          >
            Go to Settings
          </btn>
        </flex>
      </grid-column>
    </grid>
  </container>
</template>

<script>
import { api } from '@/api'
import { toast } from '@/toasts'

import btn from '@/components/v2/btn.vue'
import flex from '@/components/v2/flex.vue'
import loading from '@/components/v2/loading.vue'
import gTable from '@/components/v2/table/table.vue'
import tableCell from '@/components/v2/table/cell.vue'
import tableBody from '@/components/v2/table/body.vue'
import tableRow from '@/components/v2/table/row.vue'
import pageHeader from '@/components/v2/page_header.vue'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import gImage from '@/components/v2/image.vue'
import container from '@/components/v2/container.vue'

import customerCommunicationsMixin from '@/mixins/customerCommunicationsMixin'

export default {
  components: {
    loading,
    btn,
    flex,
    gTable,
    tableCell,
    tableBody,
    tableRow,
    pageHeader,
    grid,
    gridColumn,
    gImage,
    container,
  },
  mixins: [customerCommunicationsMixin],
  data() {
    return {
      showError: false,
      finchIntegration: null,
      organization: {},
      syncLoading: false,
      organizationLoading: false,
      usersLoading: false,
      finchLoading: false,
      onboardingUsers: [],
      activeUsers: [],
      inactiveUsers: [],
    }
  },
  computed: {
    block() {
      if (this.showError) {
        return 'error'
      }
      if (this.totalUsers.length !== 0) {
        return 'synced'
      }
      if (this.finchIntegration?.isManual) {
        return 'manual'
      }
      return 'start'
    },
    totalUsers() {
      return this.activeUsers
        .concat(this.onboardingUsers)
        .concat(this.inactiveUsers)
    },
    loading() {
      return this.finchLoading && this.usersLoading && this.organizationLoading
    },
  },
  mounted() {
    this.getPage()
  },
  methods: {
    async getPage() {
      await this.getFinchIntegration()
      await this.getUsers()
      await this.getOrganization()
    },
    async getFinchIntegration() {
      try {
        this.finchLoading = true
        this.finchIntegration = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/vendors/finch/info`
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.finchLoading = false
      }
    },
    async getOrganization() {
      try {
        this.organizationLoading = true
        this.organization = (
          await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/organizations/${this.$store.state.organizationId}`
          )
        ).data
      } catch (error) {
        toast.error(error)
      } finally {
        this.organizationLoading = false
      }
    },
    async getUsers() {
      this.usersLoading = true
      this.activeUsers = await this.getFinchUsersByStatus('active')
      this.inactiveUsers = await this.getFinchUsersByStatus('inactive')
      this.onboardingUsers = await this.getFinchUsersByStatus('onboarding')
      this.usersLoading = false
    },
    async getFinchUsersByStatus(status) {
      let pagination = {}
      let users = []
      try {
        while (pagination.nextPage !== null) {
          // eslint-disable-next-line no-await-in-loop
          const response = await api.get(
            `${process.env.VUE_APP_DB_ENDPOINT}/v2/users`,
            {
              page: parseInt(pagination.nextPage || 1, 10),
              status,
              onlyFinch: 1,
            }
          )
          users = users.concat(response.data)
          pagination = response.cursor
        }
      } catch (error) {
        toast.error(error)
      }
      return users
    },
    showErrorBlock() {
      // if there's no finch integration after a sync, then show the error page
      if (!this.finchIntegration) {
        this.showError = true
        return
      }

      // a finch sync that returns absolutely nobody is likely (but not guaranteed) an error for the user,
      // so we show the error page
      if (this.organization.finchNeedsAuth && !this.finchIntegration.isManual) {
        this.showError = true
        return
      }

      // a finch sync that returns absolutely nobody is likely (but not guaranteed) an error for the user,
      // so we show the error page
      if (this.totalUsers.length === 0) {
        this.showError = true
        return
      }

      this.showError = false
    },
    async syncFinch() {
      if (this.syncLoading) {
        return
      }

      try {
        this.syncLoading = true
        await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/rpcs/hris/sync-finch`,
          { organizationId: this.organization.id }
        )
      } catch (error) {
        toast.error(error)
      } finally {
        this.syncLoading = false
      }

      await this.getPage()
      this.showErrorBlock()
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';

.loading-screen {
  margin-top: 10vh;
}

.integration-img {
  max-width: 15em;
  margin-bottom: 2em;
}

.btn {
  margin: 0 2em;
}

@media screen and (max-width: 768px) {
  .ui.very.basic.fixed.table {
    .import-table {
      text-align: center;
    }
  }
}
</style>
